import React from "react"
import { FormControl, InputLabel, Chip, MenuItem, Select } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Delete } from "@mui/icons-material"
import { connectField, filterDOMProps } from "uniforms"

const useStyles = makeStyles((theme) => ({
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    }
}))

const MultiselectField = (props) => {
    const { value, onChange, label, variant, labelKey, options } = props

    filterDOMProps.register("labelKey")

    const classes = useStyles()

    const handleChange = (event) => {
        let newValues = event.target.value
        onChange(
            newValues.map((newVal) => {
                return options.find((opt) => opt.value === newVal)
            })
        )
    }

    const renderOptions = (options) => {
        return options.map((item, index) => {
            return (
                <MenuItem key={index} value={item.value}>
                    {getLabel(item.value)}
                </MenuItem>
            )
        })
    }

    const getLabel = (key) => {
        let val = options.find((opt) => opt.value === key)
        if (val != null) {
            return val[labelKey]
        } else {
            return ""
        }
    }

    const removeValue = (valueToRemove) => {
        let index = value.findIndex((obj) => obj.value === valueToRemove)
        if (index > -1) {
            let newInputValue = value
            newInputValue.splice(index, 1)
            onChange(newInputValue)
        }
    }

    const getValues = (selectedValues) => {
        if (selectedValues == null) {
            return []
        } else {
            return selectedValues.map((val) => {
                return val.value
            })
        }
    }

    return (
        <>
            <FormControl variant={variant} fullWidth {...filterDOMProps(props)}>
                <InputLabel>{label}</InputLabel>
                <Select
                    label={label}
                    value={getValues(value)}
                    onChange={handleChange}
                    variant={variant}
                    fullWidth
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value, index) => (
                                <Chip
                                    onDelete={() => {
                                        removeValue(value)
                                    }}
                                    deleteIcon={
                                        <div
                                            onMouseDown={(event) => {
                                                event.stopPropagation()
                                            }}
                                        >
                                            <Delete />
                                        </div>
                                    }
                                    key={index}
                                    label={getLabel(value)}
                                    className={classes.chip}
                                />
                            ))}
                        </div>
                    )}
                    multiple
                >
                    {renderOptions(options)}
                </Select>
            </FormControl>
        </>
    )
}

MultiselectField.defaultProps = {
    value: null,
    onChange: console.log,
    label: "Label",
    variant: "outlined",
    labelKey: "label",
    options: [
        {
            name: "name",
            value: "value"
        },
        {
            name: "name2",
            value: "value2"
        }
    ]
}

export default connectField(MultiselectField)
