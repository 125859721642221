const useUtils = () => {
    const hasObjectValues = (values) => {
        for (let key in values) {
            if (Array.isArray(values[key]) && values[key].length > 0) {
                return true
            }
            if (values[key] != null && !Array.isArray(values[key])) {
                return true
            }
        }
        return false
    }

    return {
        hasObjectValues
    }
}

export default useUtils
