import React from "react"
import { Grid, CircularProgress } from "@mui/material"

/**
 * Render loading spinner
 *
 * @param {boolean} enabled
 * @param {boolean} renderChildren is child elements rendered when spinner is enabled
 */
const Spinner = (props) => {
    const { enabled, renderChildren = true, children } = props

    const renderSpinner = () => {
        return (
            <Grid container>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            {enabled && renderSpinner()}
            {(enabled && renderChildren) || !enabled ? children : null}
        </>
    )
}

Spinner.defaultProps = {
    enabled: false,
    renderChildren: true
}

export default Spinner
