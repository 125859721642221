import { Navigate } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"

export const ProtectedRoute = ({ children }) => {
    const { cookie } = useAuthContext()

    if (!cookie) {
        return <Navigate to="/login" />
    }
    return children
}
