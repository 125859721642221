import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import App from "../src/app/App"
import reportWebVitals from "./reportWebVitals"

//const root = ReactDOM.createRoot(document.getElementById("root"));
const rootElement = document.getElementById("root")
const root = createRoot(rootElement)
root.render(
    <Suspense fallback={"loading..."}>
        <App />
    </Suspense>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
