import { createTheme, responsiveFontSizes } from "@mui/material/styles"
import { grey } from "@mui/material/colors"
import moment from "moment"

const OPEN = "10"
const PROGRESS = "20"
const DONE = "30"

let globalTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#00b5de"
        },
        secondary: {
            main: "#005a87"
        }
    }
})

let theme = createTheme({
    ...globalTheme,
    components: {
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    backgroundColor: globalTheme.palette.primary.main
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: globalTheme.palette.secondary.main
                    },
                    color: globalTheme.palette.secondary.light
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    padding: "5px",
                    zIndex: "0 !important"
                }
            }
        },
        /* MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: globalTheme.palette.secondary.main,
                },
            },
        }, */
        MuiContainer: {
            styleOverrides: {
                root: {
                    "&.mainview-container": {
                        marginTop: "20px"
                    },
                    "&.login-container": {
                        marginTop: "80px"
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.mainview-title": {
                        textAlign: "center"
                    }
                }
            }
        },
        ".positions-table": {
            styleOverrides: {
                root: {
                    marginBottom: "100px"
                }
            }
        }
        /* MuiGrid: {
            styleOverrides: {
                root: {
                    "&.current-positions-component": {
                        position: "sticky !important",
                        top: "0px !important",
                        zIndex: "99 !important",
                        textAlign: "center",
                    },
                },
            },
        }, */
        /* MuiDialogTitle: {
            styleOverrides: {
                root: {
                    textAlign: "center",
                },
            },
        }, */
        /* MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    justifyContent: "center",
                },
            },
        }, */
    }
})

theme = responsiveFontSizes(theme)

const useAppTheme = () => {
    let custom = { ...theme }

    custom.getTextColor = (background) => {
        let color = "#000"
        try {
            color = theme.palette.getContrastText(background)
        } catch (error) {
            color = theme.palette.getContrastText("#000")
        }
        return color
    }

    const customerEventColorConfig = {
        today: theme.palette.info.light,
        open: grey[400],
        progress: theme.palette.warning.light,
        done: theme.palette.success.light,
        late: theme.palette.error.light
    }

    custom.customerEventColorConfig = customerEventColorConfig

    custom.getCustomerEventColor = (state, startDate, endDate) => {
        let result = customerEventColorConfig.open
        if (state === DONE) {
            result = customerEventColorConfig.done
        } else if (moment().isAfter(moment(endDate).endOf("date"))) {
            result = customerEventColorConfig.late
        } else if (moment().isBetween(moment(startDate).startOf("date"), moment(endDate).endOf("date"))) {
            result = customerEventColorConfig.today
        } else if (state === OPEN) {
            result = customerEventColorConfig.open
        } else if (state === PROGRESS) {
            result = customerEventColorConfig.progress
        }

        return result
    }

    return custom
}

export default useAppTheme
