import React, { useState, useEffect } from "react"
import { Grid, Button, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import useCustomerEventApi from "../../hooks/api/useCustomerEventApi"
import { useAuthContext } from "../../hooks/useAuthContext"
import useCustomerEventSchema from "../../schemas/useCustomerEventSchema"
import { useComponentState } from "../../hooks/useComponentState"
import ContentDialog from "../Common/ContentDialog"
import FormComponent from "../Common/FormComponent"

let formRef
let createNewOnEdit = false

const CustomerEventDialog = (props) => {
    const { open, setOpen, data, setData, afterSubmit } = props

    const { t } = useTranslation()
    const CustomerEventApi = useCustomerEventApi()
    const { cookie } = useAuthContext()
    const { setLoading, setError, componentState } = useComponentState()

    const [customerCompanyCode, setCustomerCompanyCode] = useState(null)
    const { formSchema } = useCustomerEventSchema(customerCompanyCode)

    useEffect(() => {
        setCustomerCompanyCode(data?.customer?.CompanyCode || null)
    }, [data])

    const onSubmit = (formValues) => {
        setLoading(true)
        setError(null)
        const {
            customer,
            contactPerson,
            responsible,
            salesman,
            StartDateTime,
            EndDateTime,
            Importance,
            State,
            Type,
            Info,
            Description,
            RecordId,
            ModHash
        } = formValues
        let values = {
            CompanyCode: customer?.CompanyCode,
            PersonCode: contactPerson?.PersonCode,
            Responsible: responsible?.Responsible || responsible?.PersonCode,
            Salesman: salesman?.Salesman || salesman?.PersonCode,
            StartDateTime: StartDateTime.format("YYYY-MM-DDTHH:mm:ss.SSSS"),
            EndDateTime: EndDateTime.format("YYYY-MM-DDTHH:mm:ss.SSSS"),
            Importance: Importance,
            State: State,
            Type: Type,
            Info: Info,
            Description: Description
        }
        let promises = []
        let valuesForNewCustomerEvent = null
        if (RecordId != null && ModHash != null) {
            if (createNewOnEdit) {
                valuesForNewCustomerEvent = {
                    customer: customer,
                    contactPerson: contactPerson,
                    responsible: responsible,
                    salesman: salesman,
                    StartDateTime: null,
                    EndDateTime: null,
                    Importance: Importance,
                    State: "10", // Avoin
                    Type: Type,
                    Info: "",
                    Description: Description
                }
            }
            values = {
                ...values,
                RecordId: RecordId,
                ModHash: ModHash
            }
            promises.push(CustomerEventApi.updateCustomerEvent({ cookie: cookie, data: values }))
        } else {
            promises.push(CustomerEventApi.addCustomerEvent({ cookie: cookie, data: values }))
        }
        Promise.all(promises)
            .then(() => {
                if (createNewOnEdit) {
                    setData(valuesForNewCustomerEvent)
                } else {
                    setOpen(false)
                }
                afterSubmit()
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }

    const submit = (editAndCreateNew) => {
        createNewOnEdit = editAndCreateNew
        if (formRef != null) {
            formRef.submit()
        }
    }

    const renderSubmitButtons = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => submit(false)}
                        disabled={componentState.loading}
                        startIcon={componentState.loading && !createNewOnEdit && <CircularProgress size={22} />}
                    >
                        {t("save")}
                    </Button>
                </Grid>
                {data?.RecordId != null && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => submit(true)}
                            disabled={componentState.loading}
                            startIcon={componentState.loading && createNewOnEdit && <CircularProgress size={22} />}
                        >
                            {t("save_and_create_new")}
                        </Button>
                    </Grid>
                )}
            </Grid>
        )
    }

    return (
        <ContentDialog
            open={open}
            setOpen={setOpen}
            title={data?.RecordId != null ? t("edit_customer_event") : t("add_customer_event")}
            maxWidth="md"
            outsideClosable={false}
        >
            <FormComponent
                getFormRef={(ref) => (formRef = ref)}
                schema={formSchema}
                initialValues={data || {}}
                onSubmit={onSubmit}
                onChange={(key, value) => {
                    if (key === "customer") {
                        setCustomerCompanyCode(value?.CompanyCode)
                    }
                    if (key === "salesman") {
                        if (formRef != null) {
                            formRef.change("responsible", value)
                        }
                    }
                }}
                error={componentState.error}
                renderInTwoColumnsWhenWide={true}
                customSubmitRenderer={renderSubmitButtons}
            />
        </ContentDialog>
    )
}

CustomerEventDialog.defaultProps = {
    open: false,
    setOpen: () => {},
    data: null,
    setData: () => {},
    afterSubmit: () => {}
}

export default CustomerEventDialog
