import React from "react"
import { Paper, Box, Grid, Typography } from "@mui/material"

const PaperTitleContainer = (props) => {
    const { title, icon, children } = props

    return (
        <Paper elevation={2}>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} container spacing={1} alignItems="center">
                        {icon != null && <Grid item>{icon}</Grid>}
                        <Grid item>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

PaperTitleContainer.defaultProps = {
    title: "Title",
    icon: null
}

export default PaperTitleContainer
