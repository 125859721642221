import React from "react"
import { Grid, Button, CircularProgress } from "@mui/material"
import { AutoForm, AutoField } from "uniforms-mui"
import Ajv from "ajv"
import { JSONSchemaBridge } from "uniforms-bridge-json-schema"
import AppError from "../Common/AppError"

const FormComponent = (props) => {
    const {
        schema,
        initialValues,
        onSubmit,
        onChange,
        getFormRef,
        error,
        submitButtonText,
        submitButtonDisabled,
        submitButtonLoading,
        renderInTwoColumnsWhenWide,
        customFieldsRenderer,
        customSubmitRenderer,
        children
    } = props

    const ajv = new Ajv({ strict: false, allErrors: true, useDefaults: true })

    const createValidator = (schema) => {
        const validator = ajv.compile(schema)
        return (model) => {
            validator(model)
            return validator.errors?.length ? { details: validator.errors } : null
        }
    }

    let formRef
    const schemaValidator = createValidator(schema)
    const bridge = new JSONSchemaBridge(schema, schemaValidator)

    const renderFields = (fields) => {
        return (
            <>
                {fields.map((field) => {
                    return (
                        <Grid key={field} item xs={12} sm={renderInTwoColumnsWhenWide ? 6 : 12}>
                            <AutoField name={field} />
                        </Grid>
                    )
                })}
            </>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AutoForm
                    ref={(ref) => {
                        formRef = ref
                        getFormRef(ref)
                    }}
                    schema={bridge}
                    model={initialValues}
                    onSubmit={onSubmit}
                    onChange={onChange}
                >
                    <Grid container spacing={2}>
                        {customFieldsRenderer != null
                            ? customFieldsRenderer(Object.keys(schema.properties))
                            : renderFields(Object.keys(schema.properties))}
                    </Grid>
                </AutoForm>
            </Grid>
            {children != null && (
                <Grid item xs={12}>
                    {children}
                </Grid>
            )}
            {error && (
                <Grid item xs={12}>
                    <AppError error={error} />
                </Grid>
            )}
            <Grid item xs={12}>
                {customSubmitRenderer != null ? (
                    customSubmitRenderer()
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => formRef.submit()}
                        disabled={submitButtonDisabled}
                        startIcon={submitButtonLoading && <CircularProgress size={22} />}
                    >
                        {submitButtonText}
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}

FormComponent.defaultProps = {
    schema: {
        properties: {}
    },
    initialValues: {},
    onSubmit: (values) => {},
    onChange: (key, value) => {},
    getFormRef: (ref) => {}, // Use this if you need to use formRef outside of this component
    error: null,
    submitButtonText: "Submit",
    submitButtonDisabled: false,
    submitButtonLoading: false,
    renderInTwoColumnsWhenWide: false,
    customFieldsRenderer: null, // Function if set
    customSubmitRenderer: null // Function if set
}

export default FormComponent
