import React from "react"
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from "@mui/material"
import { ArrowBackIosNew, MoreVert } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../hooks/useAuthContext"

const Header = (props) => {
    const { goBack, goHome, title } = props

    const { t } = useTranslation()
    const { cookie, logout } = useAuthContext()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const onLogout = () => {
        logout()
        closeMenu()
    }

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    return (
        <AppBar position="static">
            <Toolbar>
                {goBack != null && (
                    <IconButton onClick={goBack} size="large" edge="start" color="inherit" sx={{ mr: 2 }}>
                        <ArrowBackIosNew color="primary" />
                    </IconButton>
                )}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                {cookie && (
                    <div>
                        <IconButton size="large" color="inherit" onClick={openMenu}>
                            <MoreVert />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={Boolean(anchorEl)}
                            onClose={closeMenu}
                        >
                            <MenuItem onClick={onLogout}>{t("logout")}</MenuItem>
                        </Menu>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    )
}

Header.defaultProps = {
    goBack: null,
    goHome: null,
    title: "Title"
}

export default Header
