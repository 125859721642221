import React, { useEffect, useState } from "react"
import { Autocomplete, TextField, CircularProgress } from "@mui/material"
import { connectField, filterDOMProps } from "uniforms"

const AutocompleteField = (props) => {
    const { value, onChange, label, variant, error, required, disabled, labelKeys, secondaryLabelKeys, data } = props

    filterDOMProps.register("labelKeys", "secondaryLabelKeys", "data")

    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [options, setOptions] = useState([])
    const [open, setOpen] = useState(false)

    const handleChange = (event, value, reason) => {
        onChange(value)
        setInputValue("")
    }

    const handleTextInputChange = (event) => {
        setInputValue(event.target.value)
    }

    useEffect(() => {
        if ((open || inputValue.length > 0) && !loading) {
            setLoading(true)
            data(inputValue)
                .then((result) => {
                    setOptions(result)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [inputValue, open])

    const getLabel = (valueObject) => {
        let result = "not-defined"
        let useSecondaryLabelKeys = false
        if (Array.isArray(labelKeys) && labelKeys.length > 0) {
            labelKeys.forEach((labelKey) => {
                if (valueObject[labelKey] == null) {
                    useSecondaryLabelKeys = true
                }
            })
        }
        let keys = null
        if (!useSecondaryLabelKeys) {
            keys = labelKeys
        } else {
            if (Array.isArray(secondaryLabelKeys) && secondaryLabelKeys.length > 0) {
                keys = secondaryLabelKeys
            }
        }
        if (keys != null) {
            result = ""
            keys.forEach((labelKey, index) => {
                if (index === 0 && keys.length === 1) {
                    result = valueObject[labelKey]
                } else if (index === 0 && keys.length > 1) {
                    result += "(" + valueObject[labelKey] + ")"
                } else {
                    result += " " + valueObject[labelKey]
                }
            })
        }

        return result
    }

    const getOptionLabel = (obj) => {
        return getLabel(obj)
    }

    return (
        <Autocomplete
            getOptionLabel={(obj) => getOptionLabel(obj)}
            open={open}
            onClose={() => {
                setOptions([])
                setOpen(false)
            }}
            onOpen={() => {
                setOpen(true)
            }}
            options={options}
            autoComplete
            includeInputInList
            freeSolo
            value={value}
            loading={loading}
            onChange={handleChange}
            openOnFocus={false}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...filterDOMProps(props)}
                    label={label}
                    variant={variant}
                    fullWidth
                    onChange={handleTextInputChange}
                    error={error != null}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    )
}

AutocompleteField.defaultProps = {
    value: null,
    onChange: console.log,
    label: "Label",
    variant: "outlined",
    error: null,
    required: false,
    disabled: false,
    labelKeys: [],
    secondaryLabelKeys: [],
    data: (inputValue) => {
        console.log(inputValue)
        return Promise.resolve([
            {
                name: "name",
                value: "value"
            },
            {
                name: "name2",
                value: "value2"
            }
        ])
    }
}

export default connectField(AutocompleteField)
