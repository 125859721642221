import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import useCustomerEventSchema from "../../schemas/useCustomerEventSchema"
import FormComponent from "../Common/FormComponent"

const CustomerEventSearchForm = (props) => {
    const { searchValues, searchChanged } = props

    const { t } = useTranslation()

    const [customerCompanyCode, setCustomerCompanyCode] = useState(null)
    const { searchSchema } = useCustomerEventSchema(customerCompanyCode)

    useEffect(() => {
        setCustomerCompanyCode(searchValues?.customer?.CompanyCode || null)
    }, [searchValues])

    return (
        <FormComponent
            schema={searchSchema}
            initialValues={searchValues || {}}
            onSubmit={searchChanged}
            onChange={(key, value) => {
                if (key === "customer") {
                    setCustomerCompanyCode(value?.CompanyCode)
                }
            }}
            renderInTwoColumnsWhenWide={true}
            submitButtonText={t("search")}
        />
    )
}

CustomerEventSearchForm.defaultProps = {
    searchValues: null,
    searchChanged: () => {}
}

export default CustomerEventSearchForm
