import axios from "axios"

const useApi = (baseUrl = process.env.REACT_APP_BASEURL || "http://localhost:3001") => {
    const self = {}

    /**
     * Creates axios request
     * @param {Object} requestObject Axios request object. https://github.com/axios/axios#request-config
     * @returns Response data
     */
    const request = (requestObject) => {
        return axios(requestObject).then((result) => {
            return result.data
        })
    }

    const login = (data) => {
        const requestObject = {
            url: baseUrl + "/api/auth/login",
            method: "POST",
            accept: "application/json",
            contentType: "application/json",
            data: data
        }
        return request(requestObject)
    }
    self.login = login

    const validateSession = (data) => {
        const requestObject = {
            url: baseUrl + "/api/auth/validate_session",
            method: "POST",
            accept: "application/json",
            contentType: "application/json",
            data: data
        }
        return request(requestObject)
    }
    self.validateSession = validateSession

    return self
}

export default useApi
