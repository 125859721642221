import React from "react"
import { useMediaQuery, Dialog, DialogContent, DialogTitle, DialogActions, Slide, Typography, IconButton } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Close } from "@mui/icons-material"

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const CustomDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
})

const ContentDialog = (props) => {
    const { open, setOpen, actions, title, maxWidth, classDialog, outsideClosable } = props

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Dialog
                fullScreen={isMobile}
                maxWidth={maxWidth}
                fullWidth={true}
                open={open}
                TransitionComponent={Transition}
                //keepMounted
                onClose={outsideClosable ? handleClose : () => { }}
                className={classDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <CustomDialogTitle id="alert-dialog-slide-title" onClose={handleClose}>
                    {title}
                </CustomDialogTitle>
                <DialogContent dividers>{props.children}</DialogContent>
                {actions != null && <DialogActions>{actions}</DialogActions>}
            </Dialog>
        </>
    )
}

ContentDialog.defaultProps = {
    open: true,
    title: "Title",
    setOpen: console.log,
    maxWidth: "xl",
    actions: null,
    classDialog: null,
    outsideClosable: true
}

export default ContentDialog
