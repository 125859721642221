// What languages are used in this app.
const LANGUAGES = ["fi", "en"]
// What namespaces are used in this app.
// Ignore default here.
const NAMESPACES = ["common"]

const DEFAULT_NAMESPACE = "default"

let LOCALE_PATH = "/locales/{{lng}}/{{ns}}.json"

if (typeof window != "undefined") {
    if (window.LOCALES_PATH) {
        LOCALE_PATH = window.LOCALES_PATH + "locales/{{lng}}/{{ns}}.json"
    }
}

// eslint-disable-next-line no-undef
module.exports = {
    LANGUAGES,
    LOCALE_PATH,
    NAMESPACES,
    DEFAULT_NAMESPACE
}
