import React, { useEffect, useState } from "react"
import { IconButton, Tooltip, AlertTitle, Alert } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { HelpOutline } from "@mui/icons-material"
import { grey } from "@mui/material/colors"

const useStyles = makeStyles((theme) => ({
    button: {
        color: grey[100]
    }
}))

function syntaxHighlight(json) {
    if (typeof json != "string") {
        json = JSON.stringify(json, undefined, 2)
    }
    json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
    return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
            var cls = "number"
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = "key"
                } else {
                    cls = "string"
                }
            } else if (/true|false/.test(match)) {
                cls = "boolean"
            } else if (/null/.test(match)) {
                cls = "null"
            }
            return '<span class="' + cls + '">' + match + "</span>"
        }
    )
}

/**
 *
 * @param {*} props
 */
const AppError = (props) => {
    const { error, errorButtonTitle } = props
    const classes = useStyles()
    const [title, setTitle] = useState(props.title)
    const [message, setMessage] = useState(props.message)
    const [errorState, setErrorState] = useState(null)

    useEffect(() => {
        if (error?.message) {
            let message = error.message
            if (error?.data?.message) {
                message += ":" + error.data.message
            }
            setMessage(message)
        }
        if (error?.name) {
            setTitle(error.name)
        }
    }, [error])

    const copyError = () => {
        let data = null
        if (errorState == null) {
            data = JSON.stringify(error, Object.getOwnPropertyNames(error))
            data = syntaxHighlight(data)
        }
        setErrorState(data)
    }

    if (message == null && title == null) {
        return null
    }

    return (
        <>
            <Alert variant="filled" severity="error">
                <AlertTitle>{title}</AlertTitle>
                {message}
                {error && (
                    <Tooltip title={errorButtonTitle}>
                        <IconButton onClick={copyError} className={classes.button}>
                            <HelpOutline />
                        </IconButton>
                    </Tooltip>
                )}
                {errorState && <div dangerouslySetInnerHTML={{ __html: errorState }}></div>}
            </Alert>
        </>
    )
}

AppError.defaultProps = {
    error: null,
    title: null,
    message: null,
    errorButtonTitle: "Copy error"
}

export default AppError
