import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import LocaleBackend from "i18next-http-backend"
import lngOpts from "./languageSettings"
i18n.use(LocaleBackend) // Loads the json files from public/locales
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: lngOpts.LOCALE_PATH,
            fallbackLng: "en",

            debug: true,
            react: {
                useSuspense: false,
                wait: true
            }
        },
        ns: lngOpts.NAMESPACES,
        defaultNS: lngOpts.DEFAULT_NAMESPACE,
        lng: "fi",
        saveMissing: false,
        // changeLanguage modifies language array.
        // If available langs are not in fallback array, it will remove those not specified from languages array
        // https://www.i18next.com/overview/api#languages
        fallbackLng: lngOpts.LANGUAGES,
        debug: false,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n
