import React from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ProtectedRoute } from "./protectedRoute"
import LoginView from "../app/views/Login/LoginView"
import MainView from "../app/views/Main/MainView"
import Header from "../components/Common/Header"

const PATH = {
    login: "/login",
    root: "/"
}

const useRouter = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const moveTo = (path) => {
        navigate(path)
    }
    const goBack = () => {
        navigate(-1)
    }
    const goHome = () => {
        navigate(PATH.root)
    }

    const routeList = [
        {
            path: PATH.login,
            element: (
                <>
                    <Header title={t("login")} />
                    <LoginView goHome={goHome} />
                </>
            )
        },
        {
            path: PATH.root,
            element: (
                <ProtectedRoute>
                    <Header title={t("customer_events")} />
                    <MainView />
                </ProtectedRoute>
            )
        }
    ]

    return {
        PATH,
        routeList,
        moveTo,
        goBack
    }
}

export default useRouter
