import React, { useState } from "react"
import { Alert, Backdrop, CircularProgress, Container, Snackbar } from "@mui/material"
import { Clear } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import useAuthApi from "../../../hooks/api/useAuthApi"
import { useAuthContext } from "../../../hooks/useAuthContext"
import useLoginSchema from "../../../schemas/useLoginSchema"
import FormComponent from "../../../components/Common/FormComponent"

const LoginView = (props) => {
    const { goHome } = props

    const { t } = useTranslation()
    const AuthApi = useAuthApi()
    const { login } = useAuthContext()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const { schema } = useLoginSchema()

    const onSubmit = (values) => {
        setLoading(true)
        return AuthApi.login(values)
            .then((res) => {
                if (res) {
                    login(res)
                    goHome()
                }
            })
            .catch((err) => {
                setError(err?.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Container className="login-container">
            <FormComponent onSubmit={onSubmit} schema={schema} submitButtonText={t("login")} />
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                sx={{ height: "100%" }}
                open={error ? true : false}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => {
                    setError(false)
                }}
            >
                <Alert
                    severity="error"
                    action={
                        <Clear
                            size="small"
                            onClick={() => {
                                setError(false)
                            }}
                        />
                    }
                >
                    <>{error}</>
                </Alert>
            </Snackbar>
        </Container>
    )
}

LoginView.defaultProps = {}

export default LoginView
