import React, { useRef, useEffect, useState } from "react"
import { Paper, Table, TableBody, TableCell, TableRow } from "@mui/material"
import IconText from "./IconText"

const InfoComponent = (props, ref) => {
    const { data } = props

    const componentRef = useRef()
    const [narrowComponent, setNarrowComponent] = useState(false)

    useEffect(() => {
        if (componentRef != null) {
            if (componentRef?.current?.offsetWidth < 700) {
                setNarrowComponent(true)
            } else {
                setNarrowComponent(false)
            }
        }
    }, [componentRef])

    return (
        <Paper elevation={2}>
            <Table size="small" ref={componentRef}>
                <TableBody>
                    {data.map((rowData) => {
                        return (
                            <TableRow key={rowData.title}>
                                <TableCell style={!narrowComponent ? { width: 200 } : {}}>
                                    <IconText
                                        icon={rowData.icon}
                                        text={!narrowComponent && rowData.title}
                                        tooltip={rowData.title}
                                    />
                                </TableCell>
                                <TableCell>{rowData.value}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Paper>
    )
}

InfoComponent.defaultProps = {
    data: []
}

export default InfoComponent
