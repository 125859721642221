import React, { useState, useEffect } from "react"
import { Container, Grid, Box, IconButton, Collapse, Tooltip } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { PlayArrow, List, Pause } from "@mui/icons-material"

const ViewContainer = (props) => {
    const { children, maxWidth, iconButtons } = props

    const theme = useTheme()
    const [selectedButton, setSelectedButton] = useState(-1)

    useEffect(() => {
        const index = iconButtons.findIndex((b) => b.active === true)
        setSelectedButton(index)
    }, iconButtons)

    const getIconButtonStyle = (index, button) => {
        if (index === selectedButton) {
            return { color: theme.palette.primary.main }
        } else if (button.extraHighlight) {
            return { color: theme.palette.error.main }
        } else {
            return {}
        }
    }

    const onIconButtonClick = (index, button) => {
        if (button.contentView != null) {
            if (index === selectedButton) {
                setSelectedButton(-1)
            } else {
                setSelectedButton(index)
            }
        }
        if (button.onClick != null) {
            button.onClick()
        }
    }

    const renderIconButton = (index, button) => {
        if (typeof button.icon === "string") {
            console.warn("DEPRECATION: Icon specified as a string instead of MaterialUI Icon element.")
        }
        const iconButton = (
            <IconButton
                color={"default"}
                onClick={() => onIconButtonClick(index, button)}
                style={getIconButtonStyle(index, button)}
            >
                {button.icon}
            </IconButton>
        )
        if (button.tooltip != null) {
            return <Tooltip title={button.tooltip}>{iconButton}</Tooltip>
        } else {
            return <>{iconButton}</>
        }
    }

    return (
        <Container maxWidth={maxWidth} className="mainview-container">
            <Box mb={2}>
                <Grid container justifyContent="flex-end">
                    {iconButtons.map((button, index) => {
                        return (
                            <Grid item key={index}>
                                {renderIconButton(index, button)}
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            {iconButtons.map((button, index) => {
                return (
                    <div key={index}>
                        <Collapse in={index === selectedButton} timeout="auto" unmountOnExit>
                            <Box mb={2}>{button.contentView}</Box>
                        </Collapse>
                    </div>
                )
            })}
            {children}
        </Container>
    )
}

ViewContainer.defaultProps = {
    maxWidth: "lg",
    iconButtons: [
        {
            icon: <List />,
            tooltip: "Tooltip",
            contentView: <div>test 1</div>,
            active: true
        },
        {
            icon: <PlayArrow />,
            contentView: <div>test 2</div>
        },
        {
            icon: <Pause />,
            contentView: <div>test 3</div>,
            extraHighlight: true
        }
    ]
}

export default ViewContainer
