import React, { createContext, useContext, useState, useEffect } from "react"
import { useAuthContext } from "./useAuthContext"
import useSystemCodeApi from "./api/useSystemCodeApi"
import useCompanyCodeApi from "./api/useCompanyCodeApi"
import Spinner from "../components/Common/Spinner"

const AppDataContext = createContext()

const AppDataProvider = (props) => {
    const { cookie } = useAuthContext()
    const SystemCodeApi = useSystemCodeApi()
    const CompanyCodeApi = useCompanyCodeApi()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [importances, setImportances] = useState([])
    const [states, setStates] = useState([])
    const [types, setTypes] = useState([])
    const [classifications, setClassifications] = useState([])

    useEffect(() => {
        setLoading(true)
        setError(null)
        let query = {
            cookie: cookie,
            params: {
                areacode: "COM",
                qualifier: "CRM.CntImportance",
                fields: "Code,Explanation"
            }
        }
        SystemCodeApi.getSystemCodes(query)
            .then((result) => {
                setImportances(result?.data)
                query.params.qualifier = "CRM.CntState"
                return SystemCodeApi.getSystemCodes(query)
            })
            .then((result) => {
                setStates(result?.data)
                query.params.qualifier = "CRM.CntType"
                return SystemCodeApi.getSystemCodes(query)
            })
            .then((result) => {
                setTypes(result?.data)
                query.params.qualifier = "CustClassification"
                return CompanyCodeApi.getCompanyCodes(query)
            })
            .then((result) => {
                setClassifications(result?.data)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }, [cookie])

    const value = {
        importances,
        states,
        types,
        classifications
    }

    return (
        <AppDataContext.Provider value={value}>
            {loading ? <Spinner enabled={loading} /> : props.children}
        </AppDataContext.Provider>
    )
}

const useAppDataContext = () => {
    const context = useContext(AppDataContext)
    if (context === undefined) {
        throw new Error("useAppDataContext must be used within a AppDataProvider")
    } else {
        return context
    }
}

export { AppDataProvider, useAppDataContext, AppDataContext }

export default AppDataContext
