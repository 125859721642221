import React from "react"
import { ThemeProvider } from "@mui/material/styles"
import { HashRouter, useRoutes } from "react-router-dom"
import useRouter from "../routes/useRouter"
import useAppTheme from "../hooks/theme/useAppTheme"
import CssBaseline from "@mui/material/CssBaseline"
import i18n from "../locales/i18n"
import "moment/locale/fi"
import { AuthProvider } from "../hooks/useAuthContext"
import { AppDataProvider } from "../hooks/useAppDataContext"

const WrapApp = (props) => {
    const theme = useAppTheme()
    return (
        <AuthProvider {...props}>
            <AppDataProvider {...props}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <HashRouter>
                        <App theme={theme} />
                    </HashRouter>
                </ThemeProvider>
            </AppDataProvider>
        </AuthProvider>
    )
}

const App = (props) => {
    const { theme } = props
    const { routeList } = useRouter({ theme })
    const routes = useRoutes(routeList)
    return routes
}

export default WrapApp
