import React from "react"
import { Tooltip, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    text: {
        flexWrap: "wrap",
        marginLeft: theme.spacing(1)
    }
}))

const IconText = (props) => {
    const { icon, text, tooltip } = props

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Tooltip title={tooltip}>
                <div className={icon}>{icon}</div>
            </Tooltip>
            <Typography className={classes.text}>{text}</Typography>
        </div>
    )
}

IconText.defaultProps = {
    icon: null,
    text: "",
    tooltip: ""
}

export default IconText
