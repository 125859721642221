import React, { useState, useEffect, useRef } from "react"
import { Search, Add, Help, Refresh } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import useUtils from "../../../hooks/useUtils"
import useAppTheme from "../../../hooks/theme/useAppTheme"
import ViewContainer from "../../../components/Common/ViewContainer"
import PaperTitleContainer from "../../../components/Common/PaperTitleContainer"
import ContentDialog from "../../../components/Common/ContentDialog"
import CustomerEventTable from "../../../components/CustomerEvent/CustomerEventTable"
import CustomerEventDialog from "../../../components/CustomerEvent/CustomerEventDialog"
import CustomerInfo from "../../../components/Customer/CustomerInfo"
import HelpComponent from "../../../components/Common/HelpComponent"
import CustomerEventSearchForm from "../../../components/CustomerEvent/CustomerEventSearchForm"

const MainView = () => {
    const { t } = useTranslation()
    const [savedSearchValues, setSavedSearchValues] = useLocalStorage("search-customer-events", null)
    const Utils = useUtils()
    const theme = useAppTheme()

    const tableRef = useRef()
    const [searchValues, setSearchValues] = useState(() => {
        let values = savedSearchValues || {}
        values.startDate = moment()
        if (values?.state == null) {
            values.state = [{ value: "10" }, { value: "20" }]
        }
        return values
    })
    const [highlightSearchButton, setHighlightSearchButton] = useState(false)
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [editDialogData, setEditDialogData] = useState(null)
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false)
    const [customerCompanyCode, setCustomerCompanyCode] = useState(null)

    useEffect(() => {
        setSavedSearchValues(searchValues)
        setHighlightSearchButton(Utils.hasObjectValues(searchValues))
    }, [searchValues])

    useEffect(() => {
        if (editDialogData != null) {
            setEditDialogOpen(true)
        } else {
            setEditDialogOpen(false)
        }
    }, [editDialogData])

    useEffect(() => {
        if (!editDialogOpen) {
            setEditDialogData(null)
        }
    }, [editDialogOpen])

    useEffect(() => {
        if (customerCompanyCode != null) {
            setCustomerDialogOpen(true)
        } else {
            setCustomerDialogOpen(false)
        }
    }, [customerCompanyCode])

    useEffect(() => {
        if (!customerDialogOpen) {
            setCustomerCompanyCode(null)
        }
    }, [customerDialogOpen])

    const searchChanged = (values) => {
        setSearchValues((prevValues) => {
            return { ...prevValues, ...values }
        })
    }

    const updateTable = () => {
        if (tableRef?.current != null) {
            tableRef.current.updateData()
        }
    }

    const iconButtons = [
        {
            icon: <Add />,
            tooltip: t("add_customer_event"),
            onClick: () => {
                setEditDialogData({
                    customer: searchValues?.customer || null,
                    contactPerson: searchValues?.contactPerson || null,
                    salesman: searchValues?.salesman || null,
                    responsible: searchValues?.responsible || searchValues?.salesman || null,
                    Importance: "10",
                    State: "10",
                    Type: "10"
                })
            }
        },
        {
            icon: <Search />,
            tooltip: t("search"),
            extraHighlight: highlightSearchButton,
            contentView: (
                <PaperTitleContainer title={t("search")} icon={<Search />}>
                    <CustomerEventSearchForm searchValues={searchValues} searchChanged={searchChanged} />
                </PaperTitleContainer>
            )
        },
        {
            icon: <Help />,
            tooltip: t("help"),
            contentView: (
                <PaperTitleContainer title={t("help")} icon={<Help />}>
                    <HelpComponent
                        translator={t}
                        colorConfigs={[{ title: t("states_of_customer_events"), config: theme.customerEventColorConfig }]}
                    />
                </PaperTitleContainer>
            )
        },
        {
            icon: <Refresh />,
            tooltip: t("refresh"),
            onClick: () => searchChanged(searchValues)
        }
    ]

    return (
        <>
            <ViewContainer iconButtons={iconButtons}>
                <CustomerEventTable
                    searchValues={searchValues}
                    setEditDialogData={setEditDialogData}
                    setCustomerCompanyCode={setCustomerCompanyCode}
                    ref={tableRef}
                />
            </ViewContainer>
            <CustomerEventDialog
                open={editDialogOpen}
                setOpen={setEditDialogOpen}
                data={editDialogData}
                setData={setEditDialogData}
                afterSubmit={updateTable}
            />
            <ContentDialog
                open={customerDialogOpen}
                setOpen={setCustomerDialogOpen}
                title={t("customer_info")}
                maxWidth="md"
                outsideClosable={false}
            >
                <CustomerInfo companyCode={customerCompanyCode} />
            </ContentDialog>
        </>
    )
}

MainView.defaultProps = {}

export default MainView
