import React from "react"
import { useTheme, Box, Grid, Typography, Chip } from "@mui/material"

const HelpComponent = (props) => {
    const { translator, colorConfigs } = props

    const theme = useTheme()

    const t = translator != null ? translator : (text) => text

    const getTextColor = (background) => {
        let color = "#000"
        try {
            color = theme.palette.getContrastText(background)
        } catch (error) {
            color = theme.palette.getContrastText("#000")
        }
        return color
    }

    const getColors = (config) => {
        return Object.entries(config).map((e) => ({ text: e[0], color: e[1] }))
    }

    const renderColorChips = (config) => {
        return (
            <Grid container spacing={1}>
                {getColors(config).map((value) => {
                    return (
                        <Grid key={value.text} item>
                            <Chip
                                label={t(value.text)}
                                style={{
                                    background: value.color,
                                    color: getTextColor(value.color)
                                }}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    const renderColorConfigs = () => {
        return (
            <Grid container spacing={2}>
                {colorConfigs.map((colorConfig, index) => {
                    return (
                        <Grid key={index} item xs={12}>
                            {colorConfig.title != null && (
                                <Box mb={0.6}>
                                    <Typography style={{ fontWeight: "bold" }}>{colorConfig.title}</Typography>
                                </Box>
                            )}
                            {renderColorChips(colorConfig.config)}
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    return (
        <Grid container spacing={2}>
            {colorConfigs && <Grid item>{renderColorConfigs()}</Grid>}
        </Grid>
    )
}

HelpComponent.defaultProps = {
    translator: null, // Same as t (const { t } = useTranslation()) from "react-i18next"
    colorConfigs: []
    // For example
    /*
    colorConfigs: [
        {
            title: "Title",
            config: {
                done: "#0f0",
                progress: "#00f",
                late: "#f00"
            }
        }
    ]
    */
}

export default HelpComponent
