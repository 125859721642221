import React, { useState, useEffect } from "react"
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import useCustomerApi from "../../hooks/api/useCustomerApi"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useComponentState } from "../../hooks/useComponentState"
import Spinner from "../../components/Common/Spinner"
import AppError from "../../components/Common/AppError"

const CustomerInfo = (props) => {
    const { companyCode } = props

    const { t } = useTranslation()
    const CustomerApi = useCustomerApi()
    const { cookie } = useAuthContext()

    const [customer, setCustomer] = useState(null)
    const { setLoading, setError, componentState } = useComponentState()

    useEffect(() => {
        if (companyCode != null) {
            fetchData()
        }
    }, [companyCode])

    const fetchData = () => {
        setLoading(true)
        let query = {
            cookie: cookie,
            params: {
                Customer: true,
                CompanyCode: companyCode,
                size: 1,
                fields: "CompanyCode,Name,CustomerCode,VendorCode,Site"
            }
        }

        return CustomerApi.getCustomers(query)
            .then((result) => {
                setCustomer(result.data[0])
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Grid container spacing={2}>
            {componentState.loading ? (
                <Grid item xs={12}>
                    <Spinner enabled={componentState.loading} />
                </Grid>
            ) : (
                <>
                    {componentState.error && (
                        <Grid item xs={12}>
                            <AppError error={componentState.error} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{t("company_code")}</TableCell>
                                        <TableCell>{customer?.CompanyCode}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t("name")}</TableCell>
                                        <TableCell>{customer?.Name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t("customer_code")}</TableCell>
                                        <TableCell>{customer?.CustomerCode}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t("vendor_code")}</TableCell>
                                        <TableCell>{customer?.VendorCode}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t("site")}</TableCell>
                                        <TableCell>{customer?.Site}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

CustomerInfo.defaultProps = {
    companyCode: null
}

export default CustomerInfo
