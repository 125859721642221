import React, { useEffect } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { TextField, IconButton } from "@mui/material"
import { Clear } from "@mui/icons-material"
import { connectField, filterDOMProps } from "uniforms"
import moment from "moment"

const PickerTypes = {
    datetime: (props) => <DateTimePicker {...props} />,
    date: (props) => <MobileDatePicker {...props} />,
    time: (props) => <TimePicker {...props} />
}

const DateTimeField = (props) => {
    const { value, onChange, label, variant, error, required, pickerType, inputFormat, canClear, useDefaultValue } = props

    filterDOMProps.register("pickerType", "inputFormat", "canClear", "useDefaultValue")

    const Picker = PickerTypes[pickerType]

    useEffect(() => {
        if (value == null) {
            if (!canClear && useDefaultValue) {
                onChange(moment())
            }
        } else if (typeof value === "string" && value.length > 0) {
            onChange(moment(value))
        }
    }, [value])

    const pickerChanged = (val) => {
        onChange(val)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Picker
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...filterDOMProps(props)}
                        fullWidth
                        label={label}
                        variant={variant}
                        required={required}
                        error={error != null}
                    />
                )}
                InputProps={{
                    endAdornment: (
                        <>
                            {canClear && (
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        pickerChanged(null)
                                    }}
                                    edge="end"
                                >
                                    <Clear />
                                </IconButton>
                            )}
                        </>
                    )
                }}
                value={value}
                onChange={pickerChanged}
                inputFormat={inputFormat}
            />
        </LocalizationProvider>
    )
}

DateTimeField.defaultProps = {
    value: null,
    onChange: console.log,
    label: "Label",
    variant: "outlined",
    error: null,
    required: false,
    pickerType: "datetime",
    inputFormat: "DD.MM.YYYY HH:mm",
    canClear: false,
    useDefaultValue: true
}

export default connectField(DateTimeField)
