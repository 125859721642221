import axios from "axios"

const useApi = (baseUrl = process.env.REACT_APP_BASEURL || "http://localhost:3001") => {
    const self = {}

    /**
     * Creates axios request
     * @param {Object} requestObject Axios request object. https://github.com/axios/axios#request-config
     * @returns Response data
     */
    const request = (requestObject) => {
        return axios(requestObject).then((result) => {
            return result.data
        })
    }

    const getPersons = (params) => {
        const requestObject = {
            url: baseUrl + "/api/person/",
            method: "GET",
            accept: "application/json",
            contentType: "application/json",
            params: params
        }
        return request(requestObject)
    }
    self.getPersons = getPersons

    return self
}

export default useApi
