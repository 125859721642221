import { useTranslation } from "react-i18next"
import { LongTextField } from "uniforms-mui"
import useCustomerApi from "../hooks/api/useCustomerApi"
import usePersonApi from "../hooks/api/usePersonApi"
import { useAuthContext } from "../hooks/useAuthContext"
import { useAppDataContext } from "../hooks/useAppDataContext"
import AutocompleteField from "../components/Common/Fields/AutocompleteField"
import DateTimeField from "../components/Common/Fields/DateTimeField"
import MultiselectField from "../components/Common/Fields/MultiselectField"

const useCustomerEventSchema = (customerCompanyCode) => {
    const { t } = useTranslation()
    const CustomerApi = useCustomerApi()
    const PersonApi = usePersonApi()
    const { cookie } = useAuthContext()
    const { importances, states, types, classifications } = useAppDataContext()

    const customerData = (input) => {
        let query = {
            cookie: cookie,
            params: {
                Customer: true,
                size: 10,
                fields: "CompanyCode,Name"
            }
        }

        if (Number.isInteger(Number(input))) {
            query.params.CompanyCode = "*" + input + "*"
        } else {
            query.params.Name = "*" + input + "*"
        }

        return CustomerApi.getCustomers(query).then((res) => {
            return res.data
        })
    }

    const personData = (input, companyCode) => {
        let query = {
            cookie: cookie,
            params: {
                LastName: "*" + input + "*",
                size: 10,
                fields: "PersonCode,FirstName,LastName"
            }
        }
        if (companyCode != null) {
            query.params.CompanyCode = '"' + companyCode + '"'
        }

        return PersonApi.getPersons(query).then((res) => {
            return res.data
        })
    }

    const getOptions = (options) => {
        return options.map((option) => {
            return {
                value: option.Code,
                label: option.Explanation
            }
        })
    }

    let searchSchema = {
        title: "CustomerEventSearch",
        type: "object",
        properties: {
            startDate: {
                uniforms: {
                    component: DateTimeField,
                    label: t("start_date"),
                    pickerType: "date",
                    inputFormat: "DD.MM.YYYY",
                    canClear: true,
                    margin: "dense"
                }
            },
            endDate: {
                uniforms: {
                    component: DateTimeField,
                    label: t("end_date"),
                    pickerType: "date",
                    inputFormat: "DD.MM.YYYY",
                    canClear: true,
                    margin: "dense"
                }
            },
            customer: {
                uniforms: {
                    component: AutocompleteField,
                    label: t("customer"),
                    data: customerData,
                    labelKeys: ["CompanyCode", "Name"],
                    margin: "dense"
                }
            },
            contactPerson: {
                uniforms: {
                    component: AutocompleteField,
                    label: t("contact_person"),
                    data: (input) => personData(input, customerCompanyCode),
                    labelKeys: ["PersonCode", "FirstName", "LastName"],
                    margin: "dense"
                }
            },
            salesman: {
                uniforms: {
                    component: AutocompleteField,
                    label: t("salesman"),
                    data: (input) => personData(input, 0),
                    labelKeys: ["PersonCode", "FirstName", "LastName"],
                    margin: "dense"
                }
            },
            responsible: {
                uniforms: {
                    component: AutocompleteField,
                    label: t("responsible"),
                    data: (input) => personData(input, 0),
                    labelKeys: ["PersonCode", "FirstName", "LastName"],
                    margin: "dense"
                }
            },
            state: {
                uniforms: {
                    component: MultiselectField,
                    label: t("state"),
                    options: getOptions(states),
                    margin: "dense"
                }
            },
            classification: {
                type: "string",
                label: t("classification"),
                variant: "outlined",
                options: getOptions(classifications)
            }
        }
    }

    let formSchema = {
        title: "CustomerEventForm",
        type: "object",
        properties: {
            customer: {
                type: "object",
                uniforms: {
                    component: AutocompleteField,
                    label: t("customer"),
                    data: customerData,
                    labelKeys: ["CompanyCode", "Name"],
                    secondaryLabelKeys: ["CompanyCode", "CompanyName"],
                    margin: "dense"
                }
            },
            contactPerson: {
                type: "object",
                uniforms: {
                    component: AutocompleteField,
                    label: t("contact_person"),
                    data: (input) => personData(input, customerCompanyCode),
                    labelKeys: ["PersonCode", "FirstName", "LastName"],
                    secondaryLabelKeys: ["PersonCode", "PersonName"],
                    margin: "dense"
                }
            },
            salesman: {
                type: "object",
                uniforms: {
                    component: AutocompleteField,
                    label: t("salesman"),
                    data: (input) => personData(input, 0),
                    labelKeys: ["PersonCode", "FirstName", "LastName"],
                    secondaryLabelKeys: ["Salesman", "SalesmanName"],
                    margin: "dense"
                }
            },
            responsible: {
                type: "object",
                uniforms: {
                    component: AutocompleteField,
                    label: t("responsible"),
                    data: (input) => personData(input, 0),
                    labelKeys: ["PersonCode", "FirstName", "LastName"],
                    secondaryLabelKeys: ["Responsible", "ResponsibleName"],
                    margin: "dense"
                }
            },
            StartDateTime: {
                //type: "object",
                uniforms: {
                    component: DateTimeField,
                    label: t("start_date"),
                    pickerType: "date",
                    inputFormat: "DD.MM.YYYY",
                    useDefaultValue: true,
                    margin: "dense"
                }
            },
            EndDateTime: {
                //type: "object",
                uniforms: {
                    component: DateTimeField,
                    label: t("end_date"),
                    pickerType: "date",
                    inputFormat: "DD.MM.YYYY",
                    useDefaultValue: true,
                    margin: "dense"
                }
            },
            Importance: {
                type: "string",
                label: t("importance"),
                variant: "outlined",
                options: getOptions(importances)
            },
            State: {
                type: "string",
                label: t("state"),
                variant: "outlined",
                options: getOptions(states)
            },
            Type: {
                type: "string",
                label: t("type"),
                variant: "outlined",
                options: getOptions(types)
            },
            Info: {
                type: "string",
                label: t("info")
            },
            Description: {
                type: "string",
                label: t("description"),
                rows: 4,
                uniforms: {
                    component: LongTextField
                }
            }
        },
        required: [
            "customer",
            "contactPerson",
            "salesman",
            "responsible",
            "StartDateTime",
            "EndDateTime",
            "Importance",
            "State",
            "Type"
        ]
    }

    return {
        searchSchema,
        formSchema
    }
}

export default useCustomerEventSchema
