import { useState } from "react"

/**
 * Hook to handle component error and loading state
 */
const useComponentState = () => {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    return {
        componentState: {
            systemError: error, // for backward compatibility
            loading,
            error
        },
        setSystemError: setError, // for backward compatibility
        setError,
        setLoading
    }
}

useComponentState.defaultProps = {
    initError: false,
    initLoading: false
}

export { useComponentState }
