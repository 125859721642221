import React, { createContext, useContext, useMemo, useState } from "react"
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "./useLocalStorage"
import useAuthApi from "./api/useAuthApi"
import Spinner from "../components/Common/Spinner"

const AuthContext = createContext()

/**
 * Retrieve data before mounting component.
 * @param {*} func
 * @param {*} props
 */
const useComponentWillMount = (func, props) => {
    useMemo(func, props)
}

const AuthProvider = (props) => {
    const { t } = useTranslation()
    const AuthApi = useAuthApi()

    const [cookie, setCookie] = useLocalStorage("cookie", null)
    const [isValidating, setIsValidating] = useState(false)

    const login = (data) => {
        setCookie(data.cookie)
    }

    const logout = () => {
        setCookie(null)
    }

    // Hack for loading data from server before rendering element
    // https://stackoverflow.com/a/56818036
    useComponentWillMount(() => {
        if (!isValidating) {
            setIsValidating(true)
            AuthApi.validateSession({ cookie: cookie })
                .catch(() => {
                    setCookie(null)
                })
                .finally(() => {
                    setIsValidating(false)
                })
        }
    }, [])

    const value = useMemo(
        () => ({
            cookie,
            login,
            logout
        }),
        [cookie]
    )

    return (
        <AuthContext.Provider value={value}>
            {isValidating ? (
                <Spinner enabled={isValidating}>
                    <Typography variant="body1">{t("validating_session")}</Typography>
                </Spinner>
            ) : (
                props.children
            )}
        </AuthContext.Provider>
    )
}

const useAuthContext = () => {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error("useAuthContext must be used within a AuthProvider")
    } else {
        return context
    }
}

export { AuthProvider, useAuthContext, AuthContext }

export default AuthContext
