import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react"
import { Tooltip, IconButton } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Edit, Person, Group, PlayArrow, Stop, PriorityHigh, Loop, EventNote, Info, Notes } from "@mui/icons-material"
import MaterialReactTable from "material-react-table"
import moment from "moment"
import { useTranslation } from "react-i18next"
import useCustomerEventApi from "../../hooks/api/useCustomerEventApi"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import useAppTheme from "../../hooks/theme/useAppTheme"
import InfoComponent from "../Common/InfoComponent"

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.grey[700]
    }
}))

const CustomerEventTable = forwardRef((props, ref) => {
    const { searchValues, setEditDialogData, setCustomerCompanyCode } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const CustomerEventApi = useCustomerEventApi()
    const { cookie } = useAuthContext()
    const theme = useAppTheme()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [rowCount, setRowCount] = useState(0)
    const [sorting, setSorting] = useState([{ id: "StartDateTime", desc: false }])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    })
    const [savedColumnVisibility, setSavedColumnVisibility] = useLocalStorage("column-visibility-customer-events", {})
    const [columnVisibility, setColumnVisibility] = useState(savedColumnVisibility)

    useImperativeHandle(ref, () => ({
        updateData: () => {
            fetchData(pagination.pageIndex, pagination.pageSize)
        }
    }))

    useEffect(() => {
        setPagination((prevValues) => ({ ...prevValues, pageIndex: 0 }))
        fetchData(0, pagination.pageSize)
    }, [searchValues])

    useEffect(() => {
        fetchData(pagination.pageIndex, pagination.pageSize)
    }, [pagination, sorting])

    useEffect(() => {
        setSavedColumnVisibility(columnVisibility)
    }, [columnVisibility])

    const fetchData = (pageIndex, pageSize) => {
        setLoading(true)
        let params = {
            size: pageSize,
            skip: pageIndex * pageSize
        }
        if (sorting.length > 0) {
            let sortby = sorting[0].id
            if (sortby === "StartDateTime") {
                sortby = "StartDate"
            } else if (sortby === "EndDateTime") {
                sortby = "EndDate"
            } else if (sortby === "ImportanceName") {
                sortby = "Importance"
            }
            params.sortby = sortby
            params.sortorder = sorting[0].desc ? "desc" : "asc"
        }
        if (searchValues?.customer != null) {
            params.Customer = searchValues?.customer?.CompanyCode
        }
        if (searchValues?.contactPerson != null) {
            params.PersonCode = searchValues?.contactPerson?.PersonCode
        }
        if (searchValues?.salesman != null) {
            params.Salesman = searchValues?.salesman?.PersonCode
        }
        if (searchValues?.responsible != null) {
            params.Responsible = searchValues?.responsible?.PersonCode
        }
        if (searchValues?.state && searchValues.state.length > 0) {
            let states = searchValues?.state.map((s) => s?.value)
            params.State = "@" + states.join(",")
        }
        if (searchValues?.classification != null) {
            params.Classification = searchValues?.classification
        }

        let startDate = null
        let endDate = null
        if (searchValues?.startDate != null) {
            startDate = moment(searchValues.startDate).format("YYYY-MM-DD")
        }
        if (searchValues?.endDate != null) {
            endDate = moment(searchValues.endDate).format("YYYY-MM-DD")
        }
        if (startDate != null && endDate == null) {
            params.StartDate = startDate + "->"
        } else if (startDate == null && endDate != null) {
            params.EndDate = "<-" + endDate
        } else if (startDate != null && endDate != null) {
            params.StartDate = startDate + "->"
            params.EndDate = "<-" + endDate
        }
        let query = {
            cookie: cookie,
            params: params
        }
        return CustomerEventApi.getCustomerEvents(query)
            .then((result) => {
                setData(result?.data)
                if (result?.more) {
                    setRowCount(pageIndex * pageSize + 2 * pageSize)
                } else {
                    setRowCount(result?.skip + result?.size)
                }
            })
            .catch((error) => {
                setError(error?.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "CompanyCode",
                header: t("customer"),
                Cell: ({ row }) => {
                    return (
                        <>
                            ({row?.original?.CompanyCode}) {row?.original?.CompanyName}
                        </>
                    )
                },
                enableSorting: false
            },
            {
                accessorKey: "PersonName",
                header: t("contact_person"),
                enableSorting: false
            },
            {
                accessorKey: "SalesmanName",
                header: t("salesman"),
                enableSorting: false
            },
            {
                accessorKey: "ResponsibleName",
                header: t("responsible"),
                enableSorting: false
            },
            {
                accessorKey: "StartDateTime",
                header: t("start_date"),
                Cell: ({ row }) => {
                    return <>{moment(row?.original?.StartDateTime).format("DD.MM.YYYY")}</>
                }
            },
            {
                accessorKey: "EndDateTime",
                header: t("end_date"),
                Cell: ({ row }) => {
                    return <>{moment(row?.original?.EndDateTime).format("DD.MM.YYYY")}</>
                }
            },
            {
                accessorKey: "ImportanceName",
                header: t("importance")
            },
            {
                accessorKey: "StateName",
                header: t("state"),
                enableSorting: false
            },
            {
                accessorKey: "TypeName",
                header: t("type"),
                enableSorting: false
            },
            {
                accessorKey: "Info",
                header: t("info"),
                enableSorting: false
            }
        ],
        [data]
    )

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            defaultColumn={{ minSize: 20, maxSize: 160, size: 80 }}
            enableRowOrdering={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={true}
            enableSorting={true}
            enableBottomToolbar={true}
            enableTopToolbar={true}
            positionActionsColumn="first"
            enableRowActions={true}
            enableEditing={false}
            enableGlobalFilter={false}
            muiTableBodyCellProps={({ row }) => {
                const { State, StartDateTime, EndDateTime } = row?.original
                let background = theme.getCustomerEventColor(State, StartDateTime, EndDateTime)
                return {
                    sx: {
                        backgroundColor: background,
                        color: theme.getTextColor(background)
                    }
                }
            }}
            renderRowActions={({ row }) => {
                return (
                    <div style={{ width: "80px" }}>
                        <Tooltip title={t("edit_customer_event")}>
                            <IconButton
                                onClick={() => {
                                    setEditDialogData({
                                        ...row?.original,
                                        customer: row?.original,
                                        contactPerson: row?.original,
                                        salesman: row?.original,
                                        responsible: row?.original
                                    })
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("customer_info")}>
                            <IconButton
                                onClick={() => {
                                    setCustomerCompanyCode(row?.original?.CompanyCode)
                                }}
                            >
                                <Group />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            }}
            renderDetailPanel={({ row }) => {
                let data = [
                    {
                        title: t("customer"),
                        value: "(" + row?.original?.CompanyCode + ") " + row?.original?.CompanyName,
                        icon: <Group className={classes.icon} />
                    },
                    { title: t("contact_person"), value: row?.original?.PersonName, icon: <Person className={classes.icon} /> },
                    { title: t("salesman"), value: row?.original?.SalesmanName, icon: <Person className={classes.icon} /> },
                    {
                        title: t("responsible"),
                        value: row?.original?.ResponsibleName,
                        icon: <Person className={classes.icon} />
                    },
                    {
                        title: t("start_date"),
                        value: moment(row?.original?.StartDateTime).format("DD.MM.YYYY"),
                        icon: <PlayArrow className={classes.icon} />
                    },
                    {
                        title: t("end_date"),
                        value: moment(row?.original?.EndDateTime).format("DD.MM.YYYY"),
                        icon: <Stop className={classes.icon} />
                    },
                    {
                        title: t("importance"),
                        value: row?.original?.ImportanceName,
                        icon: <PriorityHigh className={classes.icon} />
                    },
                    { title: t("state"), value: row?.original?.StateName, icon: <Loop className={classes.icon} /> },
                    { title: t("type"), value: row?.original?.TypeName, icon: <EventNote className={classes.icon} /> },
                    { title: t("info"), value: row?.original?.Info, icon: <Info className={classes.icon} /> },
                    { title: t("description"), value: row?.original?.Description, icon: <Notes className={classes.icon} /> }
                ]
                return <InfoComponent data={data} />
            }}
            displayColumnDefOptions={{
                "mrt-row-actions": {
                    header: t("actions") //change header text
                }
            }}
            manualFiltering
            manualPagination
            manualSorting
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            onColumnVisibilityChange={setColumnVisibility}
            rowCount={rowCount}
            state={{
                isLoading: loading,
                showAlertBanner: error != null,
                pagination,
                sorting,
                columnVisibility: columnVisibility
            }}
            muiToolbarAlertBannerProps={
                error
                    ? {
                          color: "error",
                          children: "Error: " + error
                      }
                    : undefined
            }
        />
    )
})

CustomerEventTable.defaultProps = {
    searchValues: null,
    setEditDialogData: () => {},
    setCustomerCompanyCode: () => {}
}

export default CustomerEventTable
