import { useTranslation } from "react-i18next"

const useLoginSchema = () => {
    const { t } = useTranslation()

    let schema = {
        title: t("login"),
        type: "object",
        properties: {
            username: {
                type: "string",
                label: t("username")
            },
            password: {
                type: "string",
                label: t("password"),
                uniforms: { type: "password" }
            }
        },
        required: ["username", "password"]
    }

    return {
        schema
    }
}

export default useLoginSchema
